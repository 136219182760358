import React, { Component } from 'react';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';

import { getMapStatus, setMapStatus } from '../../apis/RepoService';
import { nonSpaceRegex } from '../../utils/validations';

class SetMapStatus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isActive: true,
        };

    }
    componentDidMount() {
        this.setupData();
    }

    toggleActiveBit = (isActive = true) => {
        this.setState({ isActive });
    };

    setFieldValues = (text, status, id) => {
        const {setFieldValue, setStatus} = this.props;

        setStatus('');

        setFieldValue("mapStatusNotification", text) ;
        setFieldValue("status", status);
        setFieldValue("id", id);
    };

    setupData = () => {
        getMapStatus().then(response => {
            const { data } = response.data;
            const {status, activeText, inactiveText, id} = data;

            if(status === "active") {
                this.setFieldValues(activeText, 'active', id);
                this.toggleActiveBit();
            } else {
                this.setFieldValues(inactiveText, 'inactive', id);
                this.toggleActiveBit(false);
            }
        });
    };

    toggleMapStatus = (isActive) => {
        this.toggleActiveBit(isActive);

        getMapStatus().then(response => {
            const { data } = response.data;
            const {activeText, inactiveText, id} = data;

            if(isActive) {
                this.setFieldValues(activeText, 'active', id);
            } else {
                this.setFieldValues(inactiveText, 'inactive', id);
            }
        });
    };


    render() {
        const{ isActive } = this.state;
        const {
            values, handleChange, touched, errors, status, setStatus
        } = this.props;

        const { mapStatusNotification } = values;

        return (
            <div className="wapper">
                <Form>
                    <h1 className="title">SET MAP STATUS</h1>
                    <hr />
                    <div className="flex-wrapper">
                        <div className="input-container">
                            <div className="radio-btn-wrapper">
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadioInline1" name="active"
                                           checked={isActive}
                                           onChange={() => this.toggleMapStatus(true)}
                                           className="custom-control-input" />
                                    <label className="custom-control-label label1"
                                           htmlFor="customRadioInline1">Active</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadioInline2" name="inactive"
                                           className="custom-control-input" checked={!isActive}
                                           onChange={() => this.toggleMapStatus(false)}
                                    />
                                    <label className="custom-control-label label2" htmlFor="customRadioInline2">Inactive</label>
                                </div>
                            </div>
                            <br />
                            <div className="checkbox-noti-wrapper">
                                <span>Notification Text for Rx Open Page</span>
                                <div className="mb-3">
                                    <div className="input-group mt-2">
                                        <textarea type="text" className="form-control"
                                                  placeholder="Enter Notification Here"
                                                  aria-label="Username" aria-describedby="basic-addon1"
                                                  name="mapStatusNotification"
                                                  value={mapStatusNotification}
                                                  onChange={(e) => {
                                                      setStatus({apiError: ''});
                                                      handleChange(e);
                                                  }} id="" cols="30" rows="3" />
                                    </div>
                                    {errors.mapStatusNotification && touched.mapStatusNotification && (
                                        <div className="error">{errors.mapStatusNotification}</div>
                                    )}
                                    {status ? status.success && <div className="text-success">{status.success}</div> : ''}
                                    {status ? status.apiError && <div className="error">{status.apiError}</div> : ''}
                                </div>
                            </div>
                            <div className="upload-btn-wrapper">
                                <button type="submit" className="btn btn-primary btn-block">SAVE</button>
                            </div>
                        </div>
                    </div>
                </Form>

            </div>
        );
    }
}

const SetMapStatusFormik = withFormik(
    {
        mapPropsToValues: props => ({
            status: '',
            id: null,
            mapStatusNotification: ''
        }),
        validationSchema: Yup.object().shape({
            mapStatusNotification: Yup.string()
                .matches(nonSpaceRegex, 'Please enter valid input')
                .max(180, 'The max length of 180 Characters is reached.')
                .required('Please fill out this field'),
        }),
        handleSubmit: (values, { props, setStatus }) => {
            const { status, mapStatusNotification, id} = values;

            let payload = {};

            if(status === 'active') {
                payload = {
                    status,
                    activeText: mapStatusNotification
                };
            } else {
                payload = {
                    status,
                    inactiveText: mapStatusNotification
                };
            }
            setMapStatus(id, payload).then(response => {
                setStatus({success: 'Notification updated successfully'});
            }).catch(error => {
                const {message} = error;
                setStatus({apiError: message});
                console.log(error);
            });
        },
    },
)(SetMapStatus);

export default SetMapStatusFormik;
