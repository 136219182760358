import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import { AuthenticationContext } from '../containers/AuthenticatorHOC';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const { rights } = rest;

    return (
        <AuthenticationContext.Consumer>
            {value =>   <Route {...rest} render={(props) => (
                (value.authenticationStatus && rights.includes(value.role))
                        ? <Component {...props} />
                        : <Redirect to={{
                            pathname: '/admin',
                        }} />
                )} />}
        </AuthenticationContext.Consumer>
    );
};
