import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class ClearLocationModal extends React.Component {

    render() {
        const { clearSingleLocation, onHide } =this.props;
        return (
            <>
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Location Not Found</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Data has been updated and this location no longer exists on the map.
                        Do you want to remove this location from the saved locations list?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="btn btn-primary"
                                onClick={() => clearSingleLocation()}>
                            Remove Location
                        </Button>
                        <Button variant="btn btn-primary"
                                onClick={() => onHide()}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default ClearLocationModal;
