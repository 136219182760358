import React from 'react';
import PropTypes from 'prop-types';
import {
    Autocomplete
} from '@react-google-maps/api';

const GMapsSearchBox = ({ onPlacesChanged, onLoad, children }) => (
    <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlacesChanged}
    >
        { children }
    </Autocomplete>
);

GMapsSearchBox.propTypes = {
    onLoad: PropTypes.func.isRequired,
    onPlacesChanged: PropTypes.func.isRequired,
};

export default GMapsSearchBox;
