import React from 'react';
import PropTypes from 'prop-types';
import { COUNTY_MAP_STATUSES } from '../../constants';

CountyContent.propTypes = {
    data: PropTypes.object.isRequired,
};

function CountyContent({ onCloseClick, onSaveLocation, data, isLocationSaved }) {
    return (
        <>
            <ul className="search-dropdown">
                <li className="wrap relative-wrap pointer-event">
                    <span className="c-pointer" onClick={onCloseClick}>X</span>
                    <div className="item">
                        <h6 className="truncate" title={data.title}>{data.title}</h6>
                        <p className="truncate" title={data.state}>{data.state}</p>
                    </div>
                </li>
                <li className="d-tab-wrapper">
                    <div className="dropdown-tabs">
                        {isLocationSaved ?
                            <span className="save-tabs icon-ic-save-alt-24-px icon-save">
                                <span className='tab-text disabled pt-1'>
                                    Saved Location
                                </span>
                            </span>
                            :
                            <span className="save-tabs icon-ic-save-alt-24-px">
                                <span onClick={() => onSaveLocation(data)} className='tab-text c-pointer pt-1'>
                                    Save Location
                                </span>
                            </span>
                        }
                    </div>
                </li>
                <li className="address">
                    <span className="truncate-full" title={data.openPharmacies}>{data.openPharmacies}</span>
                    <span className="truncate-full" title={data.unKnownPharmacies}>{data.unKnownPharmacies}</span>
                    <span className="truncate-full" title={data.pharmaciesNotParticipating}>{data.pharmaciesNotParticipating}</span>
                    <span className="truncate-full" title={data.filterType.name}>Type: {data.filterType.name}</span>
                    <span className="truncate-full" title={COUNTY_MAP_STATUSES[data.status]}>Status: {COUNTY_MAP_STATUSES[data.status]}</span>
                </li>
            </ul>
        </>
    );
}

export default CountyContent;
