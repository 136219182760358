import * as APIService from '../utils/apiRequestUtils';
import { generateAuthHeaders } from '../utils/authUtils';

export const login = (payload) => {
    return APIService.postRequest('/api/v1/auth/login', payload);
};

export const getMapStatus = () => {
    return APIService.getRequest('/api/v1/notification');
};

export const logout = () => {
    const headers = generateAuthHeaders();
    return APIService.getRequest('/api/v1/auth/logout', headers);
};

export const setMapStatus = (id, payload) => {
    const headers = generateAuthHeaders();
    return APIService.putRequest('/api/v1/notification/' + id, payload, headers);
};

export const uploadFile = (filter, type, file, updateProgressBarValue) => {
    const data = new FormData();
    data.append('file', file);
    data.append('filter', filter);
    data.append('type', type);

    const headers = generateAuthHeaders();

    const onUploadProgress= (progressEvent) => {
        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
        if (totalLength !== null) {
            updateProgressBarValue(Math.round( (progressEvent.loaded * 100) / totalLength ));
        }
    };
    return APIService.postRequest('/api/v1/map', data, headers, {}, onUploadProgress);
};

export const getMapLocations = (facilityType, lightResponse, type) => {
    return APIService.getRequest(`/api/v1/map/${facilityType}`, {},{
        type,
        light: (lightResponse ? 1 : 0),
    });
};

export const getFacilityMapLocations = (lightResponse, type) => {
    return getMapLocations('facility', lightResponse, type);
};

export const getCountyMapLocations = (lightResponse, type) => {
    return getMapLocations('county', lightResponse, type);
};

export const getFilterTypes = () => {
    const headers = generateAuthHeaders();
    return APIService.getRequest('/api/v1/filter-types', headers);
};

export const postNewType = (type, name) => {
    const payload = {type, name};
    const headers = generateAuthHeaders();
    return APIService.postRequest('/api/v1/filter-types', payload, headers);
};

export const getLocationDetails = (id, filter) => {
    return APIService.getRequest(`/api/v1/map/details/${id}`, {}, {
        filter,
    });
};

export const getAllUsers = () => {
    const headers = generateAuthHeaders();
    return APIService.getRequest('/api/v1/users', headers);
};

export const addNewUser = (payload) => {
    const headers = generateAuthHeaders();
    return APIService.postRequest('/api/v1/users', payload, headers);
};

export const editUser = (payload, id) => {
    const headers = generateAuthHeaders();
    return APIService.putRequest(`/api/v1/users/${id}`, payload, headers);
};

export const deleteUser = (id) => {
    const headers = generateAuthHeaders();
    return APIService.deleteRequest(`/api/v1/users/${id}`, headers);
};

export const updatePassword = (payload) => {
    const headers = generateAuthHeaders();
    return APIService.putRequest('/api/v1/auth/change-password', payload, headers)
};

export const forgotPassword = (email) => {
    return APIService.getRequest('/api/v1/auth/resetPasswordRequest', {}, {email})
};

export const resetPassword = (token, newPassword) => {
    return APIService.postRequest(`/api/v1/auth/resetPassword/${token}`, {newPassword})
};

export const resendActivationEmail = (email) => {
    return APIService.getRequest('/api/v1/auth/resendActivationEmail', {}, {email})
};

export const checkRestTokenValidity = (resetToken) => {
    return APIService.getRequest(`/api/v1/auth/validateResetPasswordToken/${resetToken}`);
};

export const getAllUploadedFiles = () => {
    const headers = generateAuthHeaders();
    return APIService.getRequest('/api/v1/map', headers)
};

