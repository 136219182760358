import React, {Component} from 'react';
import logo from "../../assets/images/logo.png";
import logo2 from "../../assets/images/logo2.png";
import { Link } from 'react-router-dom';
import { AuthenticationContext } from '../../containers/AuthenticatorHOC';
import Menu from '../../containers/Menu';

class Header extends Component {
    render() {
        const { showOptions } = this.props;
        const { authenticationStatus } = this.context;
        return (
            <header>
                <div className="header-overlay">
                    <div className="container">
                        <div className="logo-wrapper">
                            <div className="main-logo-wrapper">
                                <Link to="/"><img src={logo} className="rx-logo" alt="" /></Link>
                                <span>Find Nearby Open Pharmacies in Areas Impacted by Disaster</span>
                            </div>
                            <a href="https://www.healthcareready.org/" target="_blank" rel="noopener noreferrer" ><img src={logo2} className="logo-other" alt="" /></a>
                        </div>
                        { (showOptions && authenticationStatus) ? <Menu /> : null}
                    </div>
                </div>
            </header>
        );
    }
}

Header.contextType = AuthenticationContext;

export default Header;
