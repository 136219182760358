import React from 'react';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import { convertToDateTimeStr, filterCaseInsensitive } from '../../utils/commonFunctions';
import CoreShell from '../../components/CoreShell';
import { getAllUploadedFiles } from '../../apis/RepoService';


class Archives extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: []
        };

        this.columns = [
            {
                headerClassName: 'theah-silver',
                Header: 'Id',
                // accessor: 'id',
                width: 100,
                filterable: false,
                Cell: (row) => {
                    return <div>{row.index+1}</div>;
                }
            }, {
                headerClassName: 'theah-silver',
                Header: 'File Name',
                accessor: 'fileName',
                Cell: props => <div className="truncate-table c-pointer file-download">
                    <a href={props.original.filePath}>{props.value}</a>
                </div>,
            }, {
                headerClassName: 'theah-silver',
                Header: 'Type',
                accessor: 'typeName',
                Cell: props => <div className="truncate-table text-capitalize">{props.value}</div>,
            }, {
                headerClassName: 'theah-silver',
                Header: 'Imported Date',
                accessor: 'createdAt',
                filterable: false,
                Cell: props => <div className="truncate-table">{convertToDateTimeStr(props.value)}</div>,
            }];
    }

    componentDidMount() {
        getAllUploadedFiles().then(({data:{data}}) => {
            this.updateData(data);
        });
    }

    updateData = (data) => {
        this.setState({data})
    };


    render() {
        const { data } = this.state;
        return (
            <CoreShell>
                <div className="container mt-5">
                    <h1 className="title">ARCHIVES</h1>
                    {data.length ?
                        <div>
                            <ReactTable
                                // TODO: Remove slicing once response is set to 10 records from backend.
                                data={data.slice(0,10)}
                                columns={this.columns}
                                minRows={0}
                                filterable={true}
                                showPagination={false}
                                className="mt-4"
                                defaultFilterMethod={filterCaseInsensitive}
                                resizable={true}
                            />
                            <div className="note">Note: Latest 10 uploaded files available for download</div>
                        </div>
                        : <div className="no-data">No Data Available</div>
                    }

                </div>
            </CoreShell>
        );
    };
}

export default Archives;
