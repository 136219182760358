import { defaultStorageService } from './storageService';

export function getAllLocations(type) {
    return defaultStorageService.getStorage().getItem(type) || [];
}

export function setAllLocations(type, locations) {
    return defaultStorageService.getStorage().setItem(type, locations);
}


export const saveLocationInStorage = (type, location) => {
    const locations = getAllLocations(type);
    const isLocationSaved = locations.find(data => parseFloat(data.longitude) === parseFloat(location.longitude)
        && parseFloat(data.latitude) === parseFloat(location.latitude));

    if(!isLocationSaved) {
        locations.push(location);
        setAllLocations(type, locations);
    }
};

export const updateLocationInStorage = (type, location) => {
    const locations = getAllLocations(type);
    const index = locations.findIndex(loc => parseFloat(loc.latitude) === parseFloat(location.latitude)
        && parseFloat(loc.longitude) === parseFloat(location.longitude));

    if(index !== -1) {
        locations.splice(index, 1, location);
        setAllLocations(type, locations);
    }

};


export const clearAllLocationsFromStorage = (type) => {
    defaultStorageService.getStorage().removeItem(type);
};

export const clearSingleLocationFromStorage = (type, location) => {
    const locations = getAllLocations(type);
    const index = locations.findIndex(loc => parseFloat(loc.latitude) === parseFloat(location.lat)
        && parseFloat(loc.longitude) === parseFloat(location.lng));

    locations.splice(index, 1);

    clearAllLocationsFromStorage(type);

    if(locations.length) {
        setAllLocations(type, locations);
    }

};


