import React from 'react'
import PropTypes from 'prop-types';
import { GoogleMap, useLoadScript } from '@react-google-maps/api'

import { GMAPS_API_KEY } from '../../constants';

// Prevents reloading of libraries
const libraries = ['places'];

function GMaps({ center, zoom = 1, id, onLoad, onZoomChanged, children }) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: GMAPS_API_KEY,
        libraries: libraries
    });

    const renderMap = () => {
        // wrapping to a function is useful in case you want to access `window.google`
        // to eg. setup options or create latLng object, it won't be available otherwise
        // feel free to render directly if you don't need that
        return <GoogleMap
            mapContainerStyle={{
                height: "100%",
                width: "100%"
            }}
            id={id}
            zoom={zoom}
            center={center}
            onLoad={onLoad}
            onZoomChanged={onZoomChanged}
        >
            { children }
        </GoogleMap>
    };

    if (loadError) {
        return <div>Map cannot be loaded right now. Sorry !</div>
    }

    return isLoaded ? renderMap() : <div> Loading ...... </div>
}

GMaps.propTypes = {
    center: PropTypes.shape({
        lat: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.func,
        ]).isRequired,
        lng: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.func,
        ]).isRequired
    }).isRequired,
    id: PropTypes.string.isRequired,
    zoom: PropTypes.number.isRequired,
    onLoad: PropTypes.func,
    onZoomChanged: PropTypes.func
};

export default GMaps;
