import MarkerClusterer from '@google/markerclustererplus';

import { GMAPS_MARKER_CLUSTERER_IMAGE } from '../constants';

const defaultOptions = {
    minimumClusterSize: 3,
    imagePath: GMAPS_MARKER_CLUSTERER_IMAGE,
    ignoreHidden: true,
    batchSize: 250000,
    maxZoom: 20,
};

function MarkerClustererWrapper(map, markers, options = {}) {
    return new MarkerClusterer(map, markers, {
        ...defaultOptions,
        ...options
    });
}

export default MarkerClustererWrapper;
