import React, {Component} from 'react';

class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="container">
                    <div className="footer-item">
                        <ul className="item-wrapper first-item-list">
                            <li className="footer-item-title">
                                <span>ADDITIONAL RESOURCES</span>
                            </li>
                            <li className="footer-list">
                                <a href="https://findtreatment.samhsa.gov/locator" target="_blank" rel="noopener noreferrer">SAMHSA Locator</a>
                            </li>
                            <li className="footer-list">
                                <a href="https://www.redcross.org/get-help/disaster-relief-and-recovery-services/find-an-open-shelter.html"
                                   target="_blank" rel="noopener noreferrer">American Red Cross Shelter Locations</a>
                            </li>
                        </ul>
                        <ul className="item-wrapper border-rl">
                            <li className="footer-item-title">
                                <span>ABOUT</span>
                            </li>
                            <li className="footer-list">
                                <a href="https://www.healthcareready.org//system/cms/files/1799/files/original/Rx_Open_One_Pager_-_update.pdf"
                                   target="_blank" rel="noopener noreferrer">Rx Open</a>
                            </li>
                            <li className="footer-list">
                                <a href="https://www.healthcareready.org/" target="_blank" rel="noopener noreferrer">Healthcare Ready</a>
                            </li>
                            <li className="footer-list">
                                <a href="https://www.healthcareready.org/rx-on-the-run" target="_blank" rel="noopener noreferrer">Rx on the Run</a>
                            </li>
                        </ul>
                        <ul className="item-wrapper last-item-list">
                            <li className="footer-item-title">
                                <span>LEARN MORE</span>
                            </li>
                            <li className="footer-list">
                                <a href="https://www.healthcareready.org/rxopen/faq" target="_blank" rel="noopener noreferrer">Rx Open FAQs</a>
                            </li>
                            <li className="footer-list">
                                <a href="https://www.healthcareready.org//system/cms/files/1799/files/original/Rx_Open_One_Pager_-_update.pdf"
                                target="_blank" rel="noopener noreferrer">Overview</a>
                            </li>
                            <li className="footer-list">
                                <a href="https://www.healthcareready.org/system/cms/files/1583/files/original/Rx_Open_Mapping_Pharmacies_During_Disasters_webinar.pdf"
                                target="_blank" rel="noopener noreferrer">Rx Open Webinar</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="bottom-wrapper">
                    <div className="container">
                        <div className="footer-content-wrapper">
                            <div className="footer-text">
                                © 2019 HEALTHCARE READY. ALL RIGHTS RESERVED. 1325 G St NW, Suite 500, Washington, DC 20005
                            </div>
                            <div className="social-icon-wrapper">
                                <a href="https://www.linkedin.com/company/healthcare-ready" target="_blank" rel="noopener noreferrer"><i className="icon-linkedin" /></a>
                                <a href="https://www.facebook.com/HealthcareReady/" target="_blank" rel="noopener noreferrer"><i className="icon-facebook" /></a>
                                <a href="https://twitter.com/HC_Ready" target="_blank" rel="noopener noreferrer"><i className="icon-twitter" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
