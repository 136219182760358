import React from 'react';
import PropTypes from 'prop-types';

const Search = ({ onChange }) => {
    return (
        <>
            <span className="icon-search"/>
            <input type="text" className="search-input" placeholder="Search Address" onChange={onChange} />
        </>
    )
};

Search.propTypes = {
    onChange: PropTypes.func,
};

export default Search;
