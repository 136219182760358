export const scrollWindowTop = () => {
    window.scrollTo(0,0);
};

export const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return (
        row[id] !== undefined ?
            String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
            :
            true
    );
};

export const convertToDateTimeStr = (ISODate) => {
    const date = new Date(ISODate);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    let hrs = date.getHours();
    let mins = date.getMinutes();
    let sec = date.getSeconds();
    if (dt < 10) {
        dt = '0' + dt;
    }
    if (month < 10) {
        month = '0' + month;
    }
    if (hrs < 10) {
        hrs = '0' + hrs;
    }
    if (mins < 10) {
        mins = '0' + mins;
    }
    if (sec < 10) {
        sec = '0' + sec;
    }

    return `${dt}-${month}-${year} \xa0\xa0\xa0 ${hrs}:${mins}:${sec}`
};
