import { defaultStorageService } from './storageService';

export function accessTokenHandler(token) {
    if (token === undefined) {
        return defaultStorageService.getStorage().getItem('accessToken');
    }
    return defaultStorageService.getStorage().setItem('accessToken', token);
}


export const saveAuthTokensInStorage = (accessToken) => {
    accessTokenHandler(accessToken);
};

export const getAuthTokensFromStorage = () => {
    const accessToken = accessTokenHandler();

    return accessToken || null;
};

export const clearAuthTokensFromStorage = () => {
    defaultStorageService.getStorage().removeItem('accessToken');
};

export function userRoleHandler(role) {
    if (role === undefined) {
        return defaultStorageService.getStorage().getItem('role');
    }
    return defaultStorageService.getStorage().setItem('role', role);
}


export const saveUserRoleInStorage = (role) => {
    userRoleHandler(role);
};

export const getUserRoleFromStorage = () => {
    const role = userRoleHandler();

    return role || null;
};

export const clearUserRoleFromStorage = () => {
    defaultStorageService.getStorage().removeItem('role');
};

export function userNameHandler(name) {
    if (name === undefined) {
        return defaultStorageService.getStorage().getItem('name');
    }
    return defaultStorageService.getStorage().setItem('name', name);
}


export const saveUserNameInStorage = (name) => {
    userNameHandler(name);
};

export const getUserNameFromStorage = () => {
    const name = userNameHandler();

    return name || null;
};

export const clearUserNameFromStorage = () => {
    defaultStorageService.getStorage().removeItem('name');
};

export const getUserFromStorage = () => {
    const role = getUserRoleFromStorage();
    const token = getAuthTokensFromStorage();
    const name = getUserNameFromStorage();

    return {
        role: role || null,
        token: token || null,
        name: name || null
    }
};

export const setUserInStorage = (token, role, name) => {
    saveAuthTokensInStorage(token);
    saveUserRoleInStorage(role);
    saveUserNameInStorage(name);
};

export const clearUserFromStorage = () => {
    clearAuthTokensFromStorage();
    clearUserRoleFromStorage();
    clearUserNameFromStorage();
};

export const generateAuthHeaders = () => ({
    'Authorization': getAuthTokensFromStorage()
});


