import React, { Component } from 'react'
import ResetPassword from '../CofirmPassword';
import { Redirect } from 'react-router';
import { checkRestTokenValidity } from '../../apis/RepoService';
import { saveErrorInStorage } from '../../utils/errorUtils';


class ResetPasswordHOC extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isRendering: true,
            isValidToken: false
        };
    }

    componentDidMount() {
        const {match: {params: {token}}, history} = this.props;
        checkRestTokenValidity(token).then(() => {
            this.updateIsValidToken(true, this.updateIsRendering)
        }).catch(error => {
            saveErrorInStorage(error);
            history.push('/error-page');
        })
    }

    componentWillUnmount() {
        this.updateIsValidToken(false)
    }

    updateIsRendering = (isRendering = false) => {
        this.setState({isRendering})
    };

    updateIsValidToken = (isValidToken, callBack) => {
        this.setState({isValidToken}, callBack)
    };

    render() {
        const { history, match:{params:{token}}} = this.props;
        const { isValidToken, isRendering } = this.state;
        return (
            <>
                {!isRendering ?
                    isValidToken ? <ResetPassword history={history} token={token}/> : <Redirect to='/admin'/> : null}
            </>
        )
    }
}

export default ResetPasswordHOC;
