import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import CoreShell from '../../components/CoreShell';
import CountyContent from '../../components/PrivateMapInfoWindow/CountyContent';
import FacilityContent from '../../components/PrivateMapInfoWindow/FacilityContent';
import DisplayMapStatus from '../DisplayHomeMapStatus';
import PublicMap from '../PublicMap';

import { scrollWindowTop } from '../../utils/commonFunctions';
import { getCountyMapLocationsParsed, getFacilityMapLocationsParsed } from '../../apis/dataService';
import {
    COUNTY_MAP_STATUSES, COUNTY_STATUS_COUNT,
    FACILITY_MAP_STATUSES, FACILITY_STATUS_COUNT,
    GMAPS_COUNTY_MARKERS,
    GMAPS_FACILITY_MARKERS
} from '../../constants';

import FacilityMapLegend from '../../components/FacilityMapLegend';
import CountyMapLegend from '../../components/CountyMapLegend';
import MapHeading from '../../components/HomeMapHeading';
import MapFooting from '../../components/HomeMapFooting';

const mapIds = {
    facilities: 'facilities',
    counties: 'counties'
};

const tabIds = {
    facilities: 'facilities',
    counties: 'counties'
};

class PrivateMap extends React.Component {

    constructor(props) {
        super(props);

        const initialTab = tabIds.facilities;

        this.state = {
            tab: initialTab,
            canDownloadFacility: false,
            canDownloadCounty: false,
            /* these states keep the tabs mounted once rendered and hence internal states safe */
            facilityRendered: initialTab === tabIds.facilities,
            countyRendered: initialTab === tabIds.counties,
        };
    }

    componentDidMount() {
        scrollWindowTop();
    }

    setTab = (tab) => {
        this.setState((prevState) => (
            {
                tab: tab,
                facilityRendered: prevState.facilityRendered || (tab === tabIds.facilities),
                countyRendered: prevState.countyRendered || (tab === tabIds.counties),
            }
        ));
    };

    updateCanDownloadFacility = (state) => {
        this.setState({ canDownloadFacility: state});
    };

    updateCanDownloadCounty = (state) => {
        this.setState({ canDownloadCounty: state});
    };

    getFacilities = () => {
        return getFacilityMapLocationsParsed(true).then(res => {
            if(res.parsedData.length) {
                this.updateCanDownloadFacility(true);
            }
            return res;
        });
    };

    getCounties = () => {
        return getCountyMapLocationsParsed(true).then(res => {
            if(res.parsedData.length) {
                this.updateCanDownloadCounty(true);
            }
            return res;
        });
    };

    render() {
        const { tab, facilityRendered, countyRendered, canDownloadFacility, canDownloadCounty } = this.state;
        const isFacilitiesActive = tab === tabIds.facilities;

        return (
            <CoreShell>
                <DisplayMapStatus/>
                <MapHeading isFacilitiesActive = {isFacilitiesActive}/>
                <div className="container">
                    <Tabs className="nav nav-tabs custom-tabs bg-white" id="controlled-tab-example" activeKey={tab}
                          onSelect={(tab) => this.setTab(tab)}>
                        <Tab eventKey={tabIds.facilities} title="FACILITIES">
                            <div className="map-wrapper">
                                {
                                    facilityRendered &&
                                    (
                                        <PublicMap
                                            getLocations={this.getFacilities}
                                            id={mapIds.facilities}
                                            markerIcons={GMAPS_FACILITY_MARKERS}
                                            mapStatuses={FACILITY_MAP_STATUSES}
                                            statusCount={FACILITY_STATUS_COUNT}
                                            InfoWindowContent={FacilityContent}
                                            isFacilitiesActive = {isFacilitiesActive}
                                        />
                                    )
                                }
                                <FacilityMapLegend/>
                            </div>
                        </Tab>
                        <Tab eventKey={tabIds.counties} title="COUNTIES">
                            <div className="map-wrapper">
                                {
                                    countyRendered &&
                                    (
                                        <PublicMap
                                            getLocations={this.getCounties}
                                            id={mapIds.counties}
                                            markerIcons={GMAPS_COUNTY_MARKERS}
                                            mapStatuses={COUNTY_MAP_STATUSES}
                                            statusCount={COUNTY_STATUS_COUNT}
                                            InfoWindowContent={CountyContent}
                                            isFacilitiesActive = {isFacilitiesActive}
                                        />
                                    )
                                }
                                <CountyMapLegend/>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
                <MapFooting isFacility={isFacilitiesActive}
                            canDownloadCounty={canDownloadCounty}
                            canDownloadFacility={canDownloadFacility}
                            isPrivate={true}/>
            </CoreShell>
        );

    }
}
export default PrivateMap;


