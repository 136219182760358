import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { clearErrorFromStorage, getErrorFromStorage } from '../../utils/errorUtils';


class ErrorPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null
        }
    }

    setError = (error) => {
        this.setState({ error: error});
    };

    componentDidMount() {
        this.setError(getErrorFromStorage());
        clearErrorFromStorage();
    }


    render() {
        const { error } = this.state;
        return (
            <>
                <div className="wrapper">
                    <Header/>
                    <div className="content-area">
                        <div className="container p-5">
                            <h1 className="text-center error-heading">Oops!</h1>
                            <h4 className="text-center">
                                <span>{error ? error.status : "404"}</span> - <span>{error ? error.message : "Page Not Found"}</span>
                            </h4>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </>
        );
    }
}

export default ErrorPage;
