import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { postNewType } from '../../apis/RepoService';
import { nonSpaceRegex } from '../../utils/validations';

class AddNewTypeModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isFacility: true
        };

    }

    toggleType = (state = true) => {
        this.setState({
            isFacility: state
        })
    };

    setTypeValue = (value) => {
        const {setFieldValue} = this.props;
        setFieldValue('type', value);
    };

    handleFilterChange = (toggleState, value) => {
        this.toggleType(toggleState);
        this.setTypeValue(value);
    };

    onCancel = () => {
        const {resetForm, onHide} = this.props;
        resetForm();
        onHide(false);
    };

    onExit = () => {
        const {resetForm} = this.props;
        this.toggleType();
        resetForm();
    };


    render() {

        const { values, handleChange, touched, errors, status, staticContext, isSubmitting, isValidating, submitCount,
            resetForm, submitForm, validateForm, validateField, setError, setErrors, setFieldError, setFieldTouched,
            setFieldValue, setStatus, setSubmitting, setTouched, setValues, setFormikState, isValid, dirty, initialValues,
            registerField, unregisterField, handleBlur, handleReset, handleSubmit, validateOnChange, validateOnBlur,
            ...rest} = this.props;

        const {isFacility} = this.state;
        const { name } = values;

        return (
            <Modal
                {...rest}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onExited={this.onExit}
            >
                <Modal.Header>
                    <Modal.Title>Add New Type</Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        <div className="radio-btn-wrapper">
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline1"
                                       name="Facility"
                                       onChange={() => this.handleFilterChange(true, 'facility')}
                                       checked={isFacility}
                                       className="custom-radio-btn" />
                                <label className="label1"
                                       htmlFor="customRadioInline1">Facility</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline2"
                                       name="Counties"
                                       onChange={() => this.handleFilterChange(false, 'county')}
                                       checked={!isFacility}
                                       className="custom-radio-btn"
                                />
                                <label className="label2" htmlFor="customRadioInline2">County</label>
                            </div>
                            {(errors.filter && touched.filter)&& (
                                <div className="error">{errors.filter}</div>
                            )}
                        </div>
                        <br />
                        <div className="checkbox-noti-wrapper">
                            <div className="mb-3">
                                <div className="input-group mt-2">
                                    <input type="text" className="form-control"
                                           placeholder="Add new type"
                                           aria-label="Username" aria-describedby="basic-addon1"
                                           name="name"
                                           value={name}
                                           onChange={(e) => {
                                               setStatus('');
                                               handleChange(e);
                                           }} cols="30" rows="3" />
                                </div>
                                {(errors.name && touched.name)&& (
                                    <div className="error mt-3">{errors.name}</div>
                                )}
                                {status ? status.apiError && <div className="error mt-3">{status.apiError}</div> : ''}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="btn btn-primary" type="submit">
                            SAVE
                        </Button>
                        <Button variant="btn btn-primary" onClick={() => this.onCancel(false)}>
                            CANCEL
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}

const AddNewWithFormik = withFormik(
    {
        mapPropsToValues: () => ({
            type: 'facility',
            name: '',
        }),
        validationSchema: Yup.object().shape({
            type: Yup.string()
                .required('Please choose a type'),
            name: Yup.string()
                .matches(nonSpaceRegex, 'Please enter valid input')
                .max(50, 'Input value cannot be more than 50 characters')
                .required('Value is required'),
        }),
        handleSubmit: (values, { props, setStatus, resetForm }) => {
            const {type, name} = values;
            const {onSave} = props;

            postNewType(type, name).then(() => {
                onSave();
                resetForm();
            }).catch(error => {
                const {message} = error;
                setStatus({apiError: message});
                console.log(error);
            });
        },
    },
)(AddNewTypeModal);

export default AddNewWithFormik;
