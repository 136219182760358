import React from 'react';
import CoreShell from '../CoreShell';
import Login from '../../containers/Login'

import {AuthenticationContext} from '../../containers/AuthenticatorHOC';


const AdminLogin = (props) => (
        <CoreShell showOptions={false}>
            <AuthenticationContext.Consumer>
                {value => <Login context = {value} history={props.history} />}
            </AuthenticationContext.Consumer>
        </CoreShell>
    );

export default AdminLogin;
