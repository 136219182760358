import React from 'react';
import { Route, Router, Switch, Redirect } from 'react-router-dom';

import HomePage from '../containers/HomePage';
import AdminLogin from '../components/AdminLogin';
import AdminSettings from '../containers/AdminSettings';
import {PrivateRoute} from './protectedRoute';
import ErrorPage from '../components/ErrorPage';
import {Authenticator} from '../containers/AuthenticatorHOC/index';
import { createBrowserHistory } from 'history';
import  ForgotPassword  from '../components/ForgotPassword';
import Administration from '../containers/Administration';
import PrivateMap from '../containers/PrivateMap';
import UpdatePassword from '../components/UpdatePassword';
import Archives from '../containers/Archives';
import ResetPasswordHOC from '../components/ResetPasswordHOC';


export const history = createBrowserHistory();

const Routes = () => (
    <Authenticator>
        <Router history={history}>
            <Switch>
                <Route
                    exact
                    path="/"
                    component={HomePage}
                />
                <Route
                    exact
                    path="/admin"
                    component={AdminLogin}
                />
                <PrivateRoute
                    exact
                    path="/settings"
                    rights={['superAdmin', 'admin']}
                    component={AdminSettings}
                />
                <PrivateRoute
                    exact
                    path="/archives"
                    rights={['superAdmin', 'admin']}
                    component={Archives}
                />
                <PrivateRoute
                    exact
                    path="/administration"
                    rights={['superAdmin', 'admin']}
                    component={Administration}
                />
                <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                />
                <Route
                    exact
                    path="/setpassword/:token"
                    component={ResetPasswordHOC}
                />
                <PrivateRoute
                    exact
                    rights={['superAdmin', 'admin', 'partner']}
                    path="/maps"
                    component={PrivateMap}
                />
                <PrivateRoute
                    exact
                    path="/change-password"
                    rights={['superAdmin', 'admin', 'partner']}
                    component={UpdatePassword}
                />
                <Route exact
                       path="/error-page"
                       component={ErrorPage}
                />
                <Redirect from="*" to="/error-page"/>
            </Switch>
        </Router>
    </Authenticator>
);


export default Routes;
