import React, {Component} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { FILTER_LOCATIONS_STATUS_CLASSES } from '../../constants';
import { isEmpty } from 'lodash';

class FilterDropDown extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: true,
            stateObject : {}
        };
    }

    componentDidUpdate(prevProps) {
        const { statusCount, typeCount } = this.props;

        if(statusCount !== prevProps.statusCount) {
            this.generateStatusKeys(statusCount);
        }

        if(typeCount !== prevProps.typeCount) {
            this.generateTypeKeys(typeCount);
        }
    }

    generateTypeKeys = (typesObject) => {
        const newStateObject = this.generateNewStateObject();

        Object.keys(typesObject).forEach(type => {
            if(!newStateObject.hasOwnProperty(type)){
                newStateObject[type] = true;
            }
        });

        this.updateStateObject(newStateObject);
    };

    generateStatusKeys = (statusObject) => {
        const newStateObject = this.generateNewStateObject();

        Object.keys(statusObject).forEach(status => {
            if(!newStateObject.hasOwnProperty(status) && statusObject[status]){
                newStateObject[status] = true;
            }
        });

        this.updateStateObject(newStateObject);
    };


    toggleIsOpen = () => {
        const { isOpen } = this.state;
      this.setState({ isOpen: !isOpen });
    };

    generateNewStateObject = () => {
        const { stateObject } = this.state;
        return {...stateObject};
    };

    updateStateObject = (newStateObject, callBack) => {
        this.setState({stateObject: newStateObject}, () => callBack && callBack(newStateObject));
    };


    handleChange = (key) => {
        const { onFilterChange } = this.props;
        const newStateObject = this.toggleStateKeys(key);

        this.updateStateObject(newStateObject, onFilterChange);
    };

    toggleStateKeys = (key) => {
        let newStateObject = this.generateNewStateObject();

        newStateObject[key] = !newStateObject[key];
        return newStateObject;
    };

    render() {
        const {isFacilitiesActive, statusCount, typeCount, mapStatuses} = this.props;

        const { stateObject, isOpen } = this.state;

        return (
            <>
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Card.Header}  eventKey="0" onClick={this.toggleIsOpen}>
                                {isFacilitiesActive ? 'FACILITY FILTERS' : 'COUNTY FILTERS'}
                                <span className={`icon-arrow ${isOpen ? 'rotate-arrow' : ''}`} />
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                {
                                    !isEmpty(typeCount) ? <ul className="search-dropdown scroll">
                                        <li className="d-title">{isFacilitiesActive ? 'Facility Type' : 'County Type'}</li>
                                        {
                                            Object.keys(typeCount).map(type => {
                                                return (
                                                    <li className="item-wrapper" key={type}>
                                                        <input className="cbox" type="checkbox" checked={stateObject[type] || false}
                                                               onChange={() => this.handleChange(type)}/>
                                                        <span className="truncate" title={type}>{type}</span>
                                                        <span className="counting">{typeCount[type]}</span>
                                                    </li>
                                                )
                                            })
                                        }
                                        <li className="d-title pt-4">{isFacilitiesActive ? 'Facility Status' : 'County Status'}</li>
                                        {
                                            Object.keys(mapStatuses).map(status => {
                                                return (
                                                    <li className="item-wrapper" key={status}>
                                                        <input className="cbox" type="checkbox"
                                                               checked={stateObject[status] || false}
                                                               onChange={() => this.handleChange(status)}/>
                                                        <span className={`${FILTER_LOCATIONS_STATUS_CLASSES[status]} b-border`} />
                                                        <span className="w-70"> {mapStatuses[status]} </span>
                                                        <span className="counting">{statusCount[status]}</span>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul> : <div className="no-record">No Data Available</div>
                                }
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

            </>
        );
    }
}

export default FilterDropDown;
