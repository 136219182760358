import React, {Component} from 'react';
import { SAVED_LOCATIONS_STATUS_CLASSES } from '../../constants';

class SavedLocationsList extends Component {
    render() {
        const { locations, onLocationClick, clearAllSavedLocations, clearSingleLocation } = this.props;
        return (
            <>
                <ul className='search-dropdown pb-0 scroll'>
                    <li className="save-list-item clear-all">
                        <div className="item">
                            <div className=" text-right c-pointer " onClick={clearAllSavedLocations}>Clear All</div>
                        </div>
                    </li>
                    {
                        locations.map((data, index) => (
                            <li key={data.id} className="d-flex wrap save-list-item">

                                <i className="other-btn icon-bin hover-bin c-pointer cross"
                                   onClick={() => clearSingleLocation({
                                       lat: parseFloat(data.latitude),
                                       lng: parseFloat(data.longitude),}, index)}/>
                                <span className={`${SAVED_LOCATIONS_STATUS_CLASSES[data.status]} b-border`} />
                                <div className="item c-pointer" onClick={() => onLocationClick({
                                    lat: parseFloat(data.latitude),
                                    lng: parseFloat(data.longitude),
                                }, index, data.id)}>
                                    <h6 className="truncate" title={data.brand || data.title}>{data.brand || data.title}</h6>
                                    <p className="truncate" title={data.name || data.state}>{data.name || data.state}</p>
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </>
        );
    }
}

export default SavedLocationsList;
