import React, {Component} from 'react';
import img from '../../assets/images/three-arrow-img.png';

class MapHeading extends Component {
    render() {
        const {isFacilitiesActive} = this.props;
        return (
            <>
                <div className="content">
                    <div className="content-head d-flex">
                        <div className="head-img-wrapper">
                            <img src={img} alt="" />
                        </div>
                        <div>
                            <div className="head-title-wrapper">
                                <h1 className="title">{isFacilitiesActive ? 'FACILITIES MAP' : 'COUNTIES MAP'}</h1>
                                <p>Rx Open provides information on the operating status of healthcare facilities in areas impacted by a disaster. When the map is active for an event, users can use the Facilities map to search for individual facility status. The Counties map displays a summary of operating status at the county level.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default MapHeading;
