import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { addNewUser, editUser } from '../../apis/RepoService';
import { nonSpaceRegex } from '../../utils/validations';


class ManageUserModal extends React.Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { data } = this.props;

        if(prevProps.data !== data && data) {
            this.setupData(data);
        }
    }

    setEditableUser = (user) => {
      const { setFieldValue } = this.props;

      setFieldValue('firstName', user.firstName);
      setFieldValue('lastName', user.lastName);
      setFieldValue('username', user.username);
      setFieldValue('role', user.role);
    };

    setupData = (user) => {
        this.setEditableUser(user);
    };

    onCancel = () => {
        const {resetForm, onHide} = this.props;
        resetForm();
        onHide();
    };

    render() {

        const { values, handleChange, touched, errors, status, staticContext, isSubmitting, isValidating, submitCount,
            resetForm, submitForm, validateForm, validateField, setError, setErrors, setFieldError, setFieldTouched,
            setFieldValue, setStatus, setSubmitting, setTouched, setValues, setFormikState, isValid, dirty, initialValues,
            registerField, unregisterField, handleBlur, handleReset, handleSubmit, validateOnChange, validateOnBlur, data,
            ...rest} = this.props;

        const { firstName, lastName, username, role } = values;

        return (
            <Modal
                {...rest}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onExit={this.onCancel}
            >
                <Modal.Header>
                    <Modal.Title>{data ? 'Edit User' : 'Add New User'}</Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        <div className="pb-2">
                            <label>First Name</label>
                            <sup className="red-starick">*</sup>                            
                            <input type="text" className="form-control mb-2" placeholder=""
                                   aria-label="Text input with checkbox"
                                   name="firstName" value={firstName} onChange={handleChange}/>
                            {(errors.firstName && touched.firstName)&& (
                                <div className="error">{errors.firstName}</div>
                            )}
                        </div>
                        <div className="pb-2">
                            <label>Last Name</label>
                            <sup className="red-starick">*</sup>
                            <input type="text" className="form-control mb-2" placeholder=""
                                   aria-label="Text input with checkbox"
                                   name="lastName" value={lastName} onChange={handleChange}
                            />
                            {(errors.lastName && touched.lastName)&& (
                                <div className="error">{errors.lastName}</div>
                            )}
                        </div>
                        <div className="pb-2">
                            <label>Email</label>
                            <sup className="red-starick">*</sup>
                            <input type="text" className="form-control mb-2" placeholder="name@example.com"
                                   aria-label="Text input with checkbox" disabled={data}
                            name="username" value={username} onChange={handleChange}
                            />
                            {(errors.username && touched.username)&& (
                                <div className="error">{errors.username}</div>
                            )}
                        </div>
                        <div className="pb-2">
                            <label>Role</label>
                            <sup className="red-starick">*</sup>
                            <div className="input-group mb-3">
                                <select className="custom-select" id="inputGroupSelect03"
                                        aria-label="Example select with button addon"
                                        name="role" value={role} onChange={handleChange}
                                >
                                    <option value="admin">Admin</option>
                                    <option value="partner">Partner</option>
                                </select>
                                {(errors.role && touched.role)&& (
                                    <div className="error">{errors.role}</div>
                                )}
                            </div>
                        </div>
                        {status ? status.apiError && <div className="error mt-3">{status.apiError}</div> : ''}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="btn btn-primary" type="submit">
                            {data ? 'EDIT' : 'ADD'}
                        </Button>
                        <Button variant="btn btn-primary" onClick={() => this.onCancel()}>
                            CANCEL
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}

const ManageUserFormik = withFormik(
    {
        mapPropsToValues: () => ({
            username: '',
            firstName: '',
            lastName: '',
            role: 'admin'
        }),
        validationSchema: Yup.object().shape({
            username: Yup.string()
                .email('Doesn\'t look like a valid email')
                .required('Please fill this field')
                .max(100, 'Email should not exceed 100 character'),
            firstName: Yup.string()
                .matches(nonSpaceRegex, 'Please enter valid input')
                .max(25, 'First Name should not exceed 25 character')
                .required('Please fill this field'),
            lastName: Yup.string()
                .matches(nonSpaceRegex, 'Please enter valid input')
                .max(25, 'Last Name should not exceed 25 character')
                .required('Please fill this field'),
            role: Yup.string()
                .required('Please select a role'),
        }),
        handleSubmit: (values, { props, setStatus, resetForm }) => {
            const { data, onSave } = props;

            if(data) {
                editUser(values, data.id).then(() => {
                    onSave('User Updated Successfully');
                    resetForm();
                }).catch(error => {
                    const { message } = error;
                    setStatus({apiError: message});
                    console.log(error);
                })
            } else {
                addNewUser(values).then(() => {
                    onSave('User Added Successfully');
                    resetForm();
                }).catch(error => {
                    const { message } = error;
                    setStatus({apiError: message});
                    console.log(error);
                })
            }
        },
    },
)(ManageUserModal);

export default ManageUserFormik;
