import React from 'react';
import LogoutModal from '../../components/LogoutModal';

import { clearUserFromStorage } from '../../utils/authUtils';
import { logout } from '../../apis/RepoService';
import { AuthenticationContext } from '../AuthenticatorHOC';
import { scrollWindowTop } from '../../utils/commonFunctions';
import { withRouter } from 'react-router';
import { menu } from '../../constants'
import { Link } from 'react-router-dom';

class Menu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showMenu: false,
            showLogoutModal: false
        };

    }

    componentDidMount() {
        scrollWindowTop();
        this.attachMenuEvent();
    }

    componentWillUnmount() {
        this.removeMenuEvent();
    }


    toggleMenu = () => {
        const {showMenu} = this.state;
        this.setState({
            showMenu: !showMenu
        }) ;
    };

    closeMenu = () => {
        this.setState({showMenu: false});
    };

    toggleLogoutModal = (state) => {
        this.setState({
            showLogoutModal: state
        }) ;
    };

    logout = () => {
        const {history} = this.props;
        logout().then(() => {
            this.context.setAuthenticationStatus(false, "", "", "");
            clearUserFromStorage();
            history.push('/admin');
        }).catch(() =>{
            clearUserFromStorage();
            history.push('/admin');
        });
    };

    attachMenuEvent = () => {
        document.addEventListener('click', this.onOutsideClick);
    };

    removeMenuEvent = () => {
        document.removeEventListener('click', this.onOutsideClick);
    };

    onOutsideClick = (event) => {
        const menu = document.getElementById('menu');
        if(!menu.contains(event.target) && this.isVisible(menu)) {
            this.closeMenu();
        } else if(event.target.className === 'humburger icon-menu'){
            this.toggleMenu();
        }
    };

    isVisible = elem => !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length );

    render() {
        const {showMenu, showLogoutModal} = this.state;
        const { role, userName } = this.context;

        return (
            <>
                <ul id="menu" className="humburger icon-menu" >
                    <li className={`logout-text ${showMenu ? 'd-none' : ''}`}>
                        <ul className="list">
                            <li className="logout user-wrapper">
                                <span className="icon-user mr-2" />
                                <span className="user-name mt-2">{userName}</span>
                            </li>
                            {
                                menu.map(item => (
                                    item.rights.includes(role) &&
                                    <Link className="text-deco-none" key={item.id} to={item.link}>
                                        <li className="logout" onClick={this.closeMenu}>{item.name}</li>
                                    </Link>
                                ))
                            }
                            <li className="logout" onClick={() => this.toggleLogoutModal(true)}>Logout</li>
                        </ul>
                    </li>
                </ul>
                <LogoutModal show={showLogoutModal}
                             onHide={this.toggleLogoutModal}
                             logout={this.logout}
                />
            </>
        );
    }
}

Menu.contextType = AuthenticationContext;

export default withRouter(Menu);
