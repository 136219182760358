import React, { Component } from 'react';
import * as Yup from 'yup';
import { Form, withFormik } from 'formik';

import {login} from '../../apis/RepoService';
import { setUserInStorage } from '../../utils/authUtils';
import { scrollWindowTop } from '../../utils/commonFunctions';
import { passwordRegex } from '../../utils/validations';
import { Link } from 'react-router-dom';


class Login extends Component {

    componentDidMount() {
        scrollWindowTop();
    }

    render() {
        const {
            values, handleChange, touched, errors, status, setStatus
        } = this.props;

        const { email, password } = values;

        return (
            <div className="container">
                <div id="logreg-forms" className="login-form-container">
                    <Form className="form-signin">
                        <h1 className="h3 title text-center">ADMIN PANEL</h1>
                        <hr />
                        <div className="mb-3">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text icon-email" id="basic-addon1" />
                                </div>
                                <input type="email" className="form-control" placeholder="Admin"
                                       aria-label="Username" aria-describedby="basic-addon1"
                                       onChange={(e) => {
                                           setStatus('');
                                           handleChange(e)
                                       }}
                                       value={email}
                                       name="email" />

                            </div>
                            {errors.email && touched.email && (
                                <div className="error">{errors.email}</div>
                            )}
                        </div>
                        <div className="mb-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text icon-asterisk" id="basic-addon1" />
                                </div>
                                <input type="password" className="form-control" placeholder="Password"
                                       aria-label="Username" aria-describedby="basic-addon1"
                                       onChange={(e) => {
                                           setStatus('');
                                           handleChange(e)
                                       }}
                                       value={password}
                                       name="password" />
                            </div>
                            <div className="forgot-password text-right"><Link to='/forgot-password'>Forgot Password?</Link></div>
                            {(errors.password && touched.password)&& (
                                <div className="error">{errors.password}</div>
                            )}
                            {status ? status && <div className="error">{status}</div> : ''}
                        </div>
                        <button className="btn btn-primary mt-2 btn-block" type="submit">
                            <i className="fas fa-sign-in-alt" />LOGIN</button>
                        <br />
                    </Form>
                </div>
            </div>
        );
    }
}


const LoginWithFormik = withFormik(
    {
        mapPropsToValues: (props) => ({
            email: '',
            password: '',
        }),
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email('Doesn\'t look like a valid email')
                .required('Email is required!'),
            password: Yup.string()
                .required('Password is required!')
                .matches(passwordRegex,
                    'Your password must be at least 8 characters with at least 1 uppercase, 1 lowercase, 1 special character, and 1 number.')
        }),
        handleSubmit: (values, { props, setStatus }) => {
            const {email, password} = values;

            const {context, history} = props;

            const payload = {
                username: email,
                password
            };

            login(payload).then(response => {
                const { data } = response.data;
                const { accessToken, role, firstName, lastName } = data;
                const fullName = `${firstName} ${lastName}`;

                context.setAuthenticationStatus(true, accessToken, role, fullName);
                setUserInStorage(accessToken, role, fullName);
                history.push('/maps');
            }).catch(error => {
                const {message} = error;
                setStatus(message);
                console.log(error);
            });
        },
    },
)(Login);

export default LoginWithFormik;
