import React from 'react';
import CoreShell from '../../components/CoreShell';
import UserManagementList from '../../components/UserManagementList';
import ManageUserModal from '../ManageUserModal/index';
import { deleteUser, getAllUsers, resendActivationEmail } from '../../apis/RepoService';
import DeleteUserModal from '../DeleteUserModal';
import { startCase } from 'lodash/string';

class Administration extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showManageUserModal: false,
            showDeleteModal: false,
            selectedUser: null,
            users: [],
            successMessage: '',
        };

        this.deleteUserId = null;
    }

    componentDidMount() {
        this.getAllUsers();
    }

    getAllUsers = () => {
        getAllUsers().then(response => {
            const { data: { data } } = response;
            data.forEach(user => user.status = startCase(user.status));
            this.updateUsers(data);
        })
    };

    onResend = ({ username }) => {
      resendActivationEmail(username).then(() => {
          this.updateSuccessMessage('Email resent successfully')
      })
    };

    onSave = (message) => {
        this.getAllUsers();
        this.toggleManageUserModal();
        this.updateSuccessMessage(message);
    };

    openDeleteModal = (user) => {
        this.deleteUserId = user.id;
        this.toggleDeleteModal(true);
    };

    onDelete = () => {
        deleteUser(this.deleteUserId).then(() => {
            this.getAllUsers();
            this.toggleDeleteModal();
            this.updateSuccessMessage('User Deleted Successfully');
            this.deleteUserId = null;
        }).catch(error => {
            const { message } = error;
            this.updateSelectedUser(null);
            this.updateSuccessMessage(message);
            this.deleteUserId = null;
            console.log(error);
        })
    };


    toggleDeleteModal = (state = false) => {
        this.setState({showDeleteModal: state})
    };

    toggleManageUserModal = (state = false) => {
        this.setState({showManageUserModal: state})
    };

    updateSelectedUser = (data = null, callBack, state) => {
        this.setState({ selectedUser: data}, () => callBack && callBack(state))
    };

    updateSuccessMessage = (message) => {
        this.setState({ successMessage: message });
    };

    resetSuccessMessage = () => {
        this.setState({ successMessage: '' });
    };

    updateUsers = (data) => {
      this.setState({users: data})
    };

    openEditUserModal = (data) => {
        this.resetSuccessMessage();
        this.updateSelectedUser(data, this.toggleManageUserModal, true);
    };

    openAddUserModal = () => {
        this.resetSuccessMessage();
        this.updateSelectedUser(null, this.toggleManageUserModal, true);
    };

    render() {
        const { showManageUserModal, selectedUser, users, successMessage, showDeleteModal } = this.state;

        return (
            <CoreShell>
                <ManageUserModal
                    show={showManageUserModal}
                    onHide={this.toggleManageUserModal}
                    data={selectedUser}
                    onSave={this.onSave}
                />
                <DeleteUserModal
                    show={showDeleteModal}
                    onHide={this.toggleDeleteModal}
                    onDelete={this.onDelete}
                />
                <div className="container">
                    <div className="d-flex-sb pt-4 pb-4">
                        <h1 className="title">USER MANAGEMENT</h1>
                        <div className="btn btn-primary add-user-btn icon-plus"
                             onClick={() => this.openAddUserModal()}>
                            <span>ADD USER</span>
                        </div>
                    </div>
                    {successMessage && <div className="text-success">{successMessage}</div>}
                    <UserManagementList
                        data={users}
                        onEdit={this.openEditUserModal}
                        onDelete={this.openDeleteModal}
                        onResend={this.onResend}
                    />
                </div>
            </CoreShell>
        );
    };
}

export default Administration;
