import { defaultStorageService } from './storageService';

export function errorHandler(error) {
    if (error === undefined) {
        return defaultStorageService.getStorage().getItem('error');
    }
    return defaultStorageService.getStorage().setItem('error', error);
}


export const saveErrorInStorage = (error) => {
    errorHandler(error);
};

export const getErrorFromStorage = () => {
    const error = errorHandler();

    return error || null;
};

export const clearErrorFromStorage = () => {
    defaultStorageService.getStorage().removeItem('error');
};


