import React, {Component} from 'react';
import img from '../../assets/images/three-arrow-img.png';
import footerLogo1 from '../../assets/images/NCP.png';
import footerLogo2 from '../../assets/images/dataimg.png';
import config from '../../config';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { AuthenticationContext } from '../../containers/AuthenticatorHOC';

const downloadFacility = config.BASE_API_URL + '/api/v1/map/download/facility';
const downloadCounty = config.BASE_API_URL + '/api/v1/map/download/county';

class MapFooting extends Component {

    render() {
        const { isFacility, canDownloadFacility, canDownloadCounty, isPrivate } = this.props;
        const { accessToken } = this.context;

        return (
            <>
                <div className="container">
                    <div className="about-wrapper">
                        <div className="about-text">
                            This map reflects the facilities enrolled in Rx Open. If you would like to enroll your facility, please email <a href="mailto:admin@healthcareready.org">admin@healthcareready.org</a>.
                            <br/>
                            <p className="mt-2">If the status of your facility is incorrect, please email <a href="mailto:admin@healthcareready.org">admin@healthcareready.org.</a></p>

                        </div>
                        <div className="about-logos">
                            <img src={footerLogo1} className="logo1" alt=""/>
                            <img src={footerLogo2} className="logo2" alt=""/>
                        </div>
                    </div>
                    <div className="download-wrapper">
                        {
                            isFacility ?
                                canDownloadFacility ?
                                    <a href={`${downloadFacility}${isPrivate ? '?token=' + accessToken : ''}`} target="_blank" rel="noopener noreferrer">
                                        <button className="btn btn-primary box-shadow-0 mb-4">DOWNLOAD FACILITIES</button>
                                    </a> :
                                    <OverlayTrigger
                                        placement="top"
                                        trigger= 'click'
                                        overlay={
                                            <Tooltip>
                                                No data available for download
                                            </Tooltip>
                                        }
                                    >
                                        <button className="btn btn-primary box-shadow-0 mb-4 disabled" >DOWNLOAD FACILITIES</button>
                                    </OverlayTrigger>
                                :
                                canDownloadCounty ?
                                    <a href={`${downloadCounty}${isPrivate ? '?token=' + accessToken : ''}`} target="_blank" rel="noopener noreferrer">
                                        <button className="btn btn-primary box-shadow-0 mb-4">DOWNLOAD COUNTIES</button>
                                    </a>
                                    :
                                    <OverlayTrigger
                                        placement="top"
                                        trigger= 'click'
                                        overlay={
                                            <Tooltip>
                                                No data available for download
                                            </Tooltip>
                                        }
                                    >
                                        <button className="btn btn-primary box-shadow-0 mb-4 disabled" >DOWNLOAD COUNTIES</button>
                                    </OverlayTrigger>
                        }
                    </div>
                </div>
            </>
        );
    }
}

MapFooting.contextType = AuthenticationContext;

export default MapFooting;
