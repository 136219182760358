import React from 'react';
import Header from '../Header';
import Footer from '../Footer';


const CoreShell = ({ children, showOptions = true }) => (
    <div className="wrapper">
        <Header showOptions={showOptions}/>
        <div className="content-area">
            { children }
        </div>
        <Footer/>
    </div>
);

export default CoreShell;
