import React, {Component} from 'react';
import { FACILITY_MAP_STATUSES } from '../../constants';

class FacilityMapLegend extends Component {
    render() {
        return (
            <>
                <div className="map-status-wrapper">
                    <ul className="map-status-items">
                        <li className="i-open">
                                            <span className="icon-map-pin-touch">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                            </span>
                            {FACILITY_MAP_STATUSES['open']}
                        </li>
                        <li className="i-reported">
                                            <span className="icon-map-pin-touch">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                            </span>
                            {FACILITY_MAP_STATUSES['unknown']}
                        </li>
                        <li className="i-not-participating">
                                            <span className="icon-map-pin-touch">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                            </span>
                            {FACILITY_MAP_STATUSES['not participating']}
                        </li>
                        <li className="i-unknown">
                                            <span className="icon-map-pin-touch">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                            </span>
                            {FACILITY_MAP_STATUSES['closed']}
                        </li>
                    </ul>
                </div>
            </>
        );
    }
}

export default FacilityMapLegend;
