import React, {Component} from 'react';
import { getAuthTokensFromStorage, getUserNameFromStorage, getUserRoleFromStorage } from '../../utils/authUtils';

let authenticationObject = {
    authenticationStatus: false,
    accessToken: "",
    role: "",
    userName: "",
    setAuthenticationStatus: () => {}
};

function setDefaultUser () {
    const accessToken = getAuthTokensFromStorage();
    const role = getUserRoleFromStorage();
    const name = getUserNameFromStorage();

    if(accessToken && role) {
        authenticationObject = {
            authenticationStatus: true,
            accessToken: accessToken,
            role: role,
            userName: name,
            setAuthenticationStatus: () => {}
        };
    }

    return authenticationObject;
}

const AuthenticationContext = React.createContext(setDefaultUser());

class Authenticator extends Component {
    componentDidMount() {

        const { authenticationStatus, accessToken, role, userName} = AuthenticationContext._currentValue;

        this.setAuthenticationStatus(authenticationStatus, accessToken, role, userName);

        this.setState({
            isAuthenticating: false
        })

    }

    constructor(props) {
        super(props);

        this.setAuthenticationStatus = (status, token, role, userName) => {
            this.setState({
                authenticationStatus: status,
                accessToken: token,
                role,
                userName
            });
        };

        this.state = {
            authenticationStatus: false,
            accessToken: "",
            role: "",
            userName: "",
            isAuthenticating: true,
            setAuthenticationStatus: this.setAuthenticationStatus
        };

    }


    render() {
        const {children} = this.props;
        const {isAuthenticating} = this.state;
        return (
            <AuthenticationContext.Provider value={this.state}>
                {isAuthenticating ? null : children}
            </AuthenticationContext.Provider>
        );
    }

}

export {
    AuthenticationContext,
    Authenticator
}
