import React, { Component } from 'react';

import { ACCEPTABLE_FILE_FORMATS, MAX_UPLOAD_SIZE } from '../../constants';
import { shouldUploadFile } from '../../utils/validations';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import {uploadFile } from '../../apis/RepoService';
import ProgressBar from 'react-bootstrap/ProgressBar';

const ACCEPTABLE_FORMATS = ACCEPTABLE_FILE_FORMATS.join(',');

class UploadFileForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileName: 'Select .xlsx or .csv file...',
            fileError: null
        };

        this.fileInput = React.createRef();

    }


    setFileError = (error = null) => {
        this.setState({
            fileError: error,
        });
    };

    setFileName = (name = 'Select .xlsx or .csv file...') => {
        this.setState({
            fileName: name
        });
    };

    handleFileUpload = (event) => {
        const {setFieldValue, setStatus} = this.props;
        setStatus('');
        this.setFileError();
        const file = event.currentTarget.files[0];
        if (file) {
            if (shouldUploadFile(file.type, file.size, MAX_UPLOAD_SIZE)) {
                this.setFileName(file.name);
                setFieldValue('file', file);
            } else {
                this.setFileError('Incorrect file type or size. Size should be less than 15MB.')
            }
        }
    };

    render() {
        const { fileName, fileError } = this.state;
        const {
            touched, errors, status
        } = this.props;

        return (
            <Form>
                <div className="input-group">
                    <div className="custom-file">
                        <label className="custom-file-label ellipse" htmlFor="file">
                            {fileName}
                            <input type="file" className="custom-file-input d-none"
                                   id="file"
                                   name="file"
                                   ref={this.fileInput}
                                   accept={ACCEPTABLE_FORMATS}
                                   onChange={this.handleFileUpload}
                                   aria-describedby="file" />
                        </label>
                    </div>
                </div>
                <br />
                {((errors.file && touched.file) || fileError) && (
                    <div className="error">{errors.file || fileError}</div>
                )}
                {status ? status.apiError && <div className="error w-100">{status.apiError}</div> : ''}
                {status ? status.success && <div className="text-success">{status.success}</div> : ''}
                <div className="upload-btn-wrapper">
                    <button type="submit" disabled={status && status.barValue} className="btn btn-primary btn-block">IMPORT</button>
                </div>
                <br />
                { status ? status.barValue && <ProgressBar animated now={status.barValue}/> : null}
            </Form>
        );
    }
}

const UploadFileFormFormik = withFormik(
    {
        mapPropsToValues: () => ({
            file: null
        }),
        validationSchema: Yup.object().shape({
            file: Yup.mixed()
                .required('Please add a file'),
        }),
        handleSubmit: (values, { props, setStatus }) => {
            const { file} = values;
            const {selectValue} = props;
            const filter = selectValue.filter;
            const type = selectValue.id;

            const setBarValue = (value) => {
                setStatus({
                    barValue: value
                });
            };

            uploadFile(filter, type, file, setBarValue).then(response => {
                const {uploadRecordsCount} = response.data.data;
                setStatus({
                    success:'Successfully uploaded with record count ' + uploadRecordsCount,
                    barValue: null
                });
            }).catch(error => {
                const {message} = error;
                setStatus({
                    apiError: message,
                    barValue: null
                });
                console.log(error);
            });
        },
    },
)(UploadFileForm);

export default UploadFileFormFormik;
