import axios from 'axios';
import config from '../config.json';
import { clearUserFromStorage } from './authUtils';
import { saveErrorInStorage } from './errorUtils';
import { isValidErrorObject } from './validations';
import { history } from '../routes';

export const getRequest = (endpoint, additionalHeaders = {}, params = {}, responseType) => {
  const axiosConfig = {
    headers: { 'Content-Type': 'application/json', ...additionalHeaders },
    params,
    responseType,
  };
  return axios.get(`${config.BASE_API_URL}${endpoint}`, axiosConfig);
};

export const postRequest = (endpoint, payload, additionalHeaders = {}, params = {}, onUploadProgress) => {
  const axiosConfig = {
    headers: { 'Content-Type': 'application/json', ...additionalHeaders },
    params,
    onUploadProgress
  };
  return axios.post(`${config.BASE_API_URL}${endpoint}`, payload, axiosConfig);
};

export const putRequest = (endpoint, payload, additionalHeaders = {}, params = {}) => {
  const axiosConfig = {
    headers: { 'Content-Type': 'application/json', ...additionalHeaders },
    params,
  };
  return axios.put(`${config.BASE_API_URL}${endpoint}`, payload, axiosConfig);
};

export const deleteRequest = (endpoint, additionalHeaders = {}) => {
  const axiosConfig = {
    headers: { 'Content-Type': 'application/json', ...additionalHeaders },
  };
  return axios.delete(`${config.BASE_API_URL}${endpoint}`, axiosConfig);
};

const errorHandler = (error) => {
  const meta = isValidErrorObject(error);
  // if(!meta && !error.response) {
  //   return;
  // }

  const { status } = meta || error.response;

  if(status === 401) {
    clearUserFromStorage();
    history.push('/admin');
  } else if(status === 500 || status === 403 || status === 404 || status === 502) {
    saveErrorInStorage(meta);
    history.push('/error-page');
  }

  return Promise.reject(meta);
};

const responseHandler = (response) => {
  return Promise.resolve(response);
};


axios.interceptors.response.use(
    response => responseHandler(response),
    error => errorHandler(error)
);



