import React from 'react';
import { getMapStatus } from '../../apis/RepoService';
import { scrollWindowTop } from '../../utils/commonFunctions';

class DisplayMapStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mapStatusNotification: ''
        };

        this.setMapStatus = this.setMapStatus.bind(this);
    }

    componentDidMount() {
        scrollWindowTop();
        getMapStatus().then(response => {
            const { data } = response.data;
            const {status, activeText, inactiveText} = data;

            status === 'active' ? this.setMapStatus(activeText) : this.setMapStatus(inactiveText)
        });
    }

    setMapStatus = (text) => {
        this.setState({
            mapStatusNotification: text
        });
    };

    render() {
        const {mapStatusNotification} = this.state;
        return (
            <div className="notification-area">
                <div className="container">
                    <p>{mapStatusNotification}</p>
                </div>
            </div>
        );
    };
}

export default DisplayMapStatus;
