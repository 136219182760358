import React from 'react';
import CoreShell from '../../components/CoreShell';
import SetMapStatus from '../SetMapStatus';
import ImportCSV from '../ImportCSV';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { scrollWindowTop } from '../../utils/commonFunctions';


class AdminSettings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tab: 'mapStatus',
        };
    }

    componentDidMount() {
        scrollWindowTop();
    }

    setTab = (tab) => {
        this.setState({
            tab: tab
        })
    };

    render() {

        return (
            <CoreShell>
                <div className="container">
                    <Tabs activeKey={this.state.tab}
                          onSelect={(tab) => this.setTab(tab)}>
                        <Tab eventKey="mapStatus" title="Map Status">
                            <SetMapStatus />
                        </Tab>
                        <Tab eventKey="import" title="Import">
                            <ImportCSV />
                        </Tab>
                    </Tabs>
                </div>
            </CoreShell>
        );
    }
}

export default AdminSettings;
