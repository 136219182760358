import React from 'react';
import CoreShell from '../../components/CoreShell';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { passwordRegex } from '../../utils/validations';
import { resetPassword } from '../../apis/RepoService';
import { Link } from 'react-router-dom';
import { saveErrorInStorage } from '../../utils/errorUtils';


class ResetPassword extends React.Component {

    render() {

        const { values, errors, handleChange, touched, status } = this.props;
        const { newPassword, confirmPassword } = values;

        return (
            <CoreShell>
                <div className="container">
                    <div className="login-form-container">
                        <h1 className="title mb-4">Set New Password</h1>
                        <Form className="form-signin">
                            <input type="password" className="form-control mt-3" aria-label="Text input with checkbox" placeholder="New password"
                                   value={newPassword} name="newPassword" onChange={handleChange}
                            />
                            {(errors.newPassword && touched.newPassword)&& (
                                <div className="error">{errors.newPassword}</div>
                            )}
                            <input type="password" className="form-control mt-3" aria-label="Text input with checkbox" placeholder="Confirm password"
                                   value={confirmPassword} name="confirmPassword" onChange={handleChange}
                            />
                            {(errors.confirmPassword && touched.confirmPassword)&& (
                                <div className="error">{errors.confirmPassword}</div>
                            )}
                            <div className="text-right mt-3">
                                <button className="btn btn-primary btn-block" type='submit'>Submit</button>
                            </div>
                            {status && <div className="text-success mt-3">Password reset successful. Please
                                <span className="reset-password"><Link to='/admin'> login</Link></span> using your new password.</div>}
                        </Form>
                    </div>
                </div>
            </CoreShell>
        );
    };
}

const ResetPasswordFormik = withFormik(
    {
        mapPropsToValues: () => ({
            newPassword: '',
            confirmPassword: ''
        }),
        validationSchema: Yup.object().shape({
            newPassword: Yup.string()
                .required('Please fill this field')
                .matches(passwordRegex,
                    'Your password must be at least 8 characters with at least 1 uppercase, 1 lowercase, 1 special character, and 1 number.'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], "Confirm Password should match New Password")
                .required('Please fill this field')
        }),
        handleSubmit: (values, { props, setStatus, resetForm }) => {
            const { newPassword } = values;

            const { history, token } = props;
            resetPassword(token, newPassword).then(() => {
                resetForm();
                setStatus('success');
            }).catch(error => {
                saveErrorInStorage(error);
                history.push('/error-page');
            })
        }
    },
)(ResetPassword);

export default ResetPasswordFormik;
