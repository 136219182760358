import React, { Component } from 'react';
import ReactSelect, { components } from 'react-select';

import AddNewTypeModal from '../AddNewTypeModal';
import styled from 'styled-components';
import UploadFileForm from '../UploadFileForm';
import { getParsedFilterTypes } from '../../apis/dataService';

const Option = props => {
    return (
        <components.Option
            {...props}
        >
            {props.children}
        </components.Option>
    );
};

const CustomSelect = styled(ReactSelect)`
	.css-18ng2q5-group {
    color: black;
    cursor: default;
    display: block;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 0.25em;
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
    text-transform: capitalize;
}`;

class ImportCSV extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            selectOptions: [],
            defaultSelectValue: '',
            apiError: ''
        };

    }

    componentDidMount() {
        this.setDefaultDropdownValues();
    }

    setDefaultDropdownValues = () => {
        getParsedFilterTypes().then(data => {
            this.setSelectOptions(data);
            this.setSelectValue(data[0].options[0]);
        }).catch(error => {
            this.setApiError(error.message);
        });
    };

    toggleModalState = (state) => {
        this.setState({
            showModal: state
        });
    };

    setSelectValue = (value) => {
        this.setState({
            defaultSelectValue : value
        });
    };

    setSelectOptions = (value) => {
        this.setState({
            selectOptions : value
        });
    };

    setApiError = (error = '') => {
        this.setState({
            apiError: error
        });
    };

    onNewTypeAdd = () => {
        this.setDefaultDropdownValues();
        this.toggleModalState(false);
    };

    handleChange = (value) => {
        if(value.value === 'Add New') {
            this.toggleModalState(true);
        } else {
            this.setSelectValue(value);
        }
    };


    isOptionSelected= (option, selectValue) => {
        selectValue.some(i => i === option);
    };

    render() {
        const { selectOptions, defaultSelectValue, apiError} = this.state;

        return (
            <div className="wapper">
                <AddNewTypeModal show={this.state.showModal}
                                 onHide={this.toggleModalState}
                                 onSave={this.onNewTypeAdd}
                />
                <h1 className="title">IMPORT FILE</h1>
                <hr />
                <div className="upload-wrapper">
                    <div className="dropdown mb-4 text-capitalize">
                        <CustomSelect
                            placeholder="Select Type..."
                            className="zi-positive"
                            components={{ Option }}
                            options={selectOptions}
                            value={defaultSelectValue}
                            isOptionSelected={this.isOptionSelected}
                            onChange={(value) => this.handleChange(value)}/>
                    </div>
                    <UploadFileForm selectValue={defaultSelectValue}/>
                    {apiError ? <div className="error">{apiError}</div> : null}
                </div>
            </div>

        );
    }
}

export default ImportCSV;
