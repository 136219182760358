import { ACCEPTABLE_FILE_FORMATS } from '../constants';

export const passwordRegex = /^(?=^.{8,30}$)(?=.*\d)(?=.*[A-Z])(?=.*[\W])(?!.*\s).*$/;
export const nonSpaceRegex = /\S/;

// Cheap mechanism to check for JSON String
const jsonRegex = /{(.*?)}/;

export function isJSONString(value) {
  return jsonRegex.test(value);
}

export function shouldUploadFile(fileType, fileSize, validSize) {
  return isValidType(fileType) && isValidFileSize(fileSize, validSize);
}

export function isValidType(fileType) {
  return ACCEPTABLE_FILE_FORMATS.includes(fileType);
}

export function isValidFileSize(fileSize, validSize) {
  return fileSize <= validSize;
}

export function isValidErrorObject(error) {
  return error.response && error.response.data && error.response.data.meta;
}

