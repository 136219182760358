import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class LogoutModal extends React.Component {

    render() {
        const {onHide, logout} = this.props;

        return (
            <>
                <Modal
                    {...this.props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Logout</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to logout?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="btn btn-primary" onClick={() => logout()}>
                            YES
                        </Button>
                        <Button variant="btn btn-primary" onClick={() => onHide(false)}>
                            NO
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default LogoutModal;
