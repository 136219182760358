import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class DeleteUserModal extends React.Component {

    render() {
        const {onHide, onDelete} = this.props;

        return (
            <>
                <Modal
                    {...this.props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="btn btn-primary" onClick={onDelete}>
                            YES
                        </Button>
                        <Button variant="btn btn-primary" onClick={() => onHide()}>
                            NO
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default DeleteUserModal;
