import React, {Component} from 'react';
import { COUNTY_MAP_STATUSES } from '../../constants';

class CountyMapLegend extends Component {
    render() {
        return (
            <>
                <div className="map-status-wrapper">
                    <ul className="map-status-items">
                        <li className="i-open counti-color-open">
                                            <span className="icon-map-pin-touch">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                            </span>
                            {COUNTY_MAP_STATUSES['county with > 90% pharmacies open']}
                        </li>
                        <li className="i-reported counti-color-reported">
                                            <span className="icon-map-pin-touch">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                            </span>
                            {COUNTY_MAP_STATUSES['county with 75% - 90% pharmacies open']}
                        </li>
                        <li className="i-not-participating counti-color-participating">
                                            <span className="icon-map-pin-touch">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                            </span>
                            {COUNTY_MAP_STATUSES['county with 50% - 74% pharmacies open']}
                        </li>
                        <li className="i-unknown counti-color-unknown">
                                            <span className="icon-map-pin-touch">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                            </span>
                            {COUNTY_MAP_STATUSES['county with < 50% pharmacies open']}
                        </li>
                    </ul>
                </div>
            </>
        );
    }
}

export default CountyMapLegend;
