import { getFacilityMapLocations, getCountyMapLocations, getFilterTypes } from './RepoService';
import { countBy } from 'lodash';
import { COUNTY_STATUS_COUNT, FACILITY_STATUS_COUNT } from '../constants';

export function getMapLocationsParser({data : { data }}) {
    return data.map((locationData) => ({
        latLng: {
            lat: parseFloat(locationData.latitude),
            lng: parseFloat(locationData.longitude),
        },
        reliableStatus: locationData.status.toLowerCase(),
        ...locationData
    }))
}

export function getMapLocationsLightParser(args,statusCount) {
    // TODO: Temp fix for handling first null value;
    // [, ...data] = data;
    args = args || {
        data:{
            data:{
                data:[],
                schema:[],
                statusMap:{},
                types:[]
            }
        }
    }
    var {data,schema,statusMap,types}= args.data.data;
    let reverseStatusMap = Object.keys(statusMap).reduce((obj, key) => {
        obj[statusMap[key]] = key;
        return obj;
    }, {});

    let typesMap = types.reduce((obj, value) => {
        obj[value['id']] = value['name'];
        return obj;
    }, {});

    const parsedData = data.map((dataArr) => {
        let parsedObj = schema.reduce((obj, key, index) => {
            obj[key] = dataArr[index];
            return obj;
        }, {});

        parsedObj['status'] = reverseStatusMap[parsedObj['status']];
        parsedObj['type'] = typesMap[parsedObj['type']];
        parsedObj['latLng'] = {
            lat: parseFloat(parsedObj['latitude']),
            lng: parseFloat(parsedObj['longitude']),
        };

        statusCount[parsedObj['status']]++;

        return parsedObj
    });

    let typeCount = countBy(parsedData, 'type');

    return {
        parsedData,
        statusCount,
        typeCount
    };
}

export function getFilterTypesParser({data : { data }}) {
    data =  data.map(({label, options}) => ({
        options: options.map(option => ({
            id: option.id,
            value: option.value,
            label: option.value,
            filter: label
        })),
        label
    }));

    data.push({label: '-----------------------------', options: [{value: 'Add New', label: 'Add New Type'}]});
    return data;
}

export function getFacilityMapLocationsParsed(lightResponse, type) {
    return getFacilityMapLocations(lightResponse, type).then((response) => {
        return lightResponse ? getMapLocationsLightParser(response, {...FACILITY_STATUS_COUNT}) : getMapLocationsParser(response);
    });
}

export function getCountyMapLocationsParsed(lightResponse, type) {
    return getCountyMapLocations(lightResponse, type).then((response) => {
        return lightResponse ? getMapLocationsLightParser(response, {...COUNTY_STATUS_COUNT}) : getMapLocationsParser(response);
    });
}

export function getParsedFilterTypes() {
    return getFilterTypes().then(getFilterTypesParser);
}
