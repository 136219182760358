import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class RefreshModal extends React.Component {

    render() {
        return (
            <>
                <Modal
                    {...this.props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Location Not Found</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Data has been updated and this location no longer exists on the map.
                        Please refresh the page to view latest changes.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="btn btn-primary"
                                onClick={() => window.location.reload()}>
                            Okay
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default RefreshModal;
