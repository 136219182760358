import React from 'react';
import PropTypes from 'prop-types';

FacilityContent.propTypes = {
    data: PropTypes.object.isRequired,
};

function FacilityContent({ onCloseClick, onSaveLocation, data, isLocationSaved }) {

    return (
        <>
            <ul className="search-dropdown">
                <li className="wrap relative-wrap pointer-event">
                    <span className="c-pointer" onClick={onCloseClick}>X</span>
                    <div className="item">
                        <h6 className="truncate" title={data.brand}>{data.brand}</h6>
                        <p className="truncate" title={data.name}>{data.name}</p>
                    </div>
                </li>
                <li className="d-tab-wrapper">
                    <div className="dropdown-tabs">
                        {isLocationSaved ?
                            <span className="save-tabs icon-ic-save-alt-24-px icon-save">
                                <span className='tab-text disabled pt-1'>
                                    Saved Location
                                </span>
                            </span>
                            :
                            <span className="save-tabs icon-ic-save-alt-24-px">
                                <span onClick={() => onSaveLocation(data)} className='tab-text c-pointer pt-1'>
                                    Save Location
                                </span>
                            </span>
                        }
                    </div>
                </li>
                <li className="address">
                    <span className="truncate-full" title={`${data.address}, ${data.city}, ${data.state}, ${data.zip}`}>{`${data.address}, ${data.city}, ${data.state}, ${data.zip}`}</span>
                    <span className="truncate-full" title={data.phone}>{data.phone}</span>
                    <span className="truncate-full" title={data.lateOpening ? data.lateOpening : '-'}>Late Opening: {data.lateOpening ? data.lateOpening : '-'}</span>
                    <span className="truncate-full" title={data.earlyClosing ? data.earlyClosing : '-' }>Early Closing: {data.earlyClosing ? data.earlyClosing : '-' }</span>
                    <span className="truncate-full" title={data.filterType.name}>Type: {data.filterType.name}</span>
                </li>
            </ul>
        </>
    );
}

export default FacilityContent;
