import React from 'react';
import CoreShell from '../../components/CoreShell';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { forgotPassword } from '../../apis/RepoService';

class ForgotPassword extends React.Component {

    render() {
        const {
            values, handleChange, touched, errors, status
        } = this.props;

        const { username } = values;

        return (
            <CoreShell>
                <div className="login-form-container">
                    <h1 className="title mb-4">Forgot Password</h1>
                    <label>Please enter your registered email address</label>
                    <Form>
                        <input type="text" name="username" value={username} onChange={handleChange}
                               className="form-control" aria-label="Text input with checkbox" placeholder="Email" />
                        {errors.username && touched.username && (
                            <div className="error">{errors.username}</div>
                        )}
                        <div className="text-right mt-3">
                            <button className="btn btn-primary btn-block" type='submit'>Submit</button>
                        </div>
                        {status && <div className="text-success mt-3">{status}</div>}
                    </Form>
                </div>
            </CoreShell>
        );
    };
}

const ForgotPasswordFormik = withFormik(
    {
        mapPropsToValues: () => ({
            username: ''
        }),
        validationSchema: Yup.object().shape({
            username: Yup.string()
                .email('Doesn\'t look like a valid email')
                .required('Email is required!'),
        }),
        handleSubmit: (values, { props, setStatus }) => {
            const { username } = values;
            forgotPassword(username).then(() => {
                setStatus(`We have sent a password recovery email at ${username}. Please follow the link in the email to reset your password.`);
            })
        }
    },
)(ForgotPassword);

export default (ForgotPasswordFormik);

