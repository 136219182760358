import React from 'react';
import CoreShell from '../CoreShell';

import {AuthenticationContext} from '../../containers/AuthenticatorHOC';
import ChangePassword from '../../containers/ChangePassword';


const UpdatePassword = (props) => (
    <CoreShell>
        <AuthenticationContext.Consumer>
            {value => <ChangePassword context = {value} history={props.history} />}
        </AuthenticationContext.Consumer>
    </CoreShell>
);

export default UpdatePassword;
