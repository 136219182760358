import React from 'react';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { passwordRegex } from '../../utils/validations';
import { updatePassword } from '../../apis/RepoService';
import { clearUserFromStorage } from '../../utils/authUtils';



class ChangePassword extends React.Component {

    render() {
        const { values, errors, handleChange, touched, status } = this.props;
        const { password, newPassword, confirmPassword } = values;

        return (
                <div className="login-form-container">
                    <h1 className="title mb-4">Change Password</h1>
                    <Form>
                        <input type="password" className="form-control" aria-label="Text input with checkbox" placeholder=" Old Password"
                               value={password} name="password" onChange={handleChange} autoComplete="off"
                        />
                        {(errors.password && touched.password)&& (
                            <div className="error">{errors.password}</div>
                        )}
                        <input type="password" className="form-control mt-3" aria-label="Text input with checkbox" placeholder="New password"
                               value={newPassword} name="newPassword" onChange={handleChange} autoComplete="off"
                        />
                        {(errors.newPassword && touched.newPassword)&& (
                            <div className="error">{errors.newPassword}</div>
                        )}
                        <input type="password" className="form-control mt-3" aria-label="Text input with checkbox" placeholder="Confirm password"
                               value={confirmPassword} name="confirmPassword" onChange={handleChange} autoComplete="off"
                        />
                        {(errors.confirmPassword && touched.confirmPassword)&& (
                            <div className="error">{errors.confirmPassword}</div>
                        )}
                        {status ? status.apiError && <div className="error">{status.apiError}</div> : null}
                        <div className="text-right mt-3">
                            <button className="btn btn-primary btn-block" type="submit" >Submit</button>
                        </div>
                    </Form>
                </div>
    );
    };
}


const ChangePasswordFormik = withFormik(
    {
        mapPropsToValues: () => ({
            password: '',
            newPassword: '',
            confirmPassword: ''
        }),
        validationSchema: Yup.object().shape({
            password: Yup.string()
                .required('Please fill this field'),
            newPassword: Yup.string()
                .required('Please fill this field')
                .matches(passwordRegex,
                    'Your password must be at least 8 characters with at least 1 uppercase, 1 lowercase, 1 special character, and 1 number.'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], "Confirm Password should match New Password")
                .required('Please fill this field')
                .matches(passwordRegex,
                    'Your password must be at least 8 characters with at least 1 uppercase, 1 lowercase, 1 special character, and 1 number.')
        }),
        handleSubmit: (values, { props, setStatus }) => {
            const { history, context } = props;
            setStatus('');
            updatePassword(values).then(() => {
                    context.setAuthenticationStatus(false, "", "", "");
                    clearUserFromStorage();
                    history.push('/admin');
            }).catch(error => {
                const { message } = error;
                setStatus({apiError: message});
                console.log(error);
            })

        }
    },
)(ChangePassword);


export default ChangePasswordFormik;
