import React from 'react';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import { filterCaseInsensitive } from '../../utils/commonFunctions';

class UserManagementList extends React.Component {
    constructor(props) {
        super(props);

        this.columns = [{
            headerClassName: 'theah-silver',
            Header: 'Id',
            accessor: 'id',
            width: 100,
            filterable: false,
            Cell: props => <div className="truncate-table" title={props.value}>{props.value}</div>,
        }, {
            headerClassName: 'theah-silver',
            Header: 'First Name',
            accessor: 'firstName',
            Cell: props => <div className="truncate-table" title={props.value}>{props.value}</div>,
        }, {
            headerClassName: 'theah-silver',
            Header: 'Last Name',
            accessor: 'lastName',
            Cell: props => <div className="truncate-table" title={props.value}>{props.value}</div>,
        }, {
            headerClassName: 'theah-silver',
            Header: 'Email',
            accessor: 'username',
            Cell: props => <div className="truncate-table" title={props.value}>{props.value}</div>,
        } , {
            headerClassName: 'theah-silver',
            Header: 'Role',
            accessor: 'role',
            Cell: props => <div className="text-capitalize truncate-table" title={props.value}>{props.value}</div>,
            width: 150
        }, {
            headerClassName: 'theah-silver',
            Header: 'Status',
            accessor: 'status',
            Cell: props => <div className="text-capitalize truncate-table" title={props.value}>{props.value}</div>,
        }, {
            headerClassName: 'theah-silver',
            Header: 'Actions',
            filterable: false,
            Cell: props => <div className="user-icons-wrapper">
                {
                    props.row.status === 'Activated' ?
                    <i className="other-btn icon-pencil text-info mr-4 c-pointer" onClick={() => this.props.onEdit(props.row)}/>
                :
                    <i className="other-btn icon-loop text-info mr-4 c-pointer" title='Resend Request' onClick={() => this.props.onResend(props.row)}/>
                }
                <i className="other-btn icon-bin text-danger c-pointer" onClick={() => this.props.onDelete(props.row)}/>
            </div>,
            width: 150
        }];
    }

    render() {
        const { data } = this.props;
        return (
            <div>
                {data.length ?
                    <ReactTable
                        data={data}
                        columns={this.columns}
                        minRows={0}
                        filterable={true}
                        showPagination={false}
                        className="mb-5"
                        defaultFilterMethod={filterCaseInsensitive}
                        resizable={true}
                    /> : <div className="no-data">No Data Available</div>
                }
            </div>
        );
    };
}

export default UserManagementList;
